<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 19:56:09
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.page-info {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #f7f8fa;
  .info-view {
    display: flex;
    flex-direction: column;
    .header-info {
      width: 100%;
      height: 58px;
    }
    .content-info {
      min-width: 1200px;
      max-width: 1200px;
      height: calc(100% - 58px);
      background-color: white;
      padding: 0 120px;
      box-sizing: border-box;
      .title {
        color: #333333;
        font-size: 24px;
        font-weight: bold;
        margin-top: 60px;
      }
      .content {
        color: #666666;
        font-size: 18px;
        margin: 30px 0;
        line-height: 40px;
      }
    }
  }
}
</style>
<template>
  <div class="page-info">
    <div class="info-view">
      <questionnaire-header
        class="header-info"
        :hisPage="pageName"
      ></questionnaire-header>
      <div class="content-info">
        <div class="title">帮助中心</div>
        <p class="content">
          1、金币规则。
          <br>什么是金币？
          <br>金币是蚂蚁调研网的奖励货币，参与活动可获得相应金币，在【兑换中心】可以将金币兑换成人民币，然后定期通过支付宝支付给用户。</p>
        <p class="content">
          如何获得金币？
          <br>完善资料：在【我的-个人资料】中完善资料可获得一定金币。
          <br>参与调研：在【任务中心】，参与调研问卷可获得相应金币奖励。
          <br>回答第一份问卷：奖励50金币。
          <br>其它：我们会陆续推出有金币奖励的活动。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionnaireHeader from "@/components/QuestionnaireHeader.vue";

export default {
  name: "helpCenter",
  components: {
    QuestionnaireHeader,
  },
  data() {
    return {
      pageName: "帮助中心",
      hintTitle: "",
      
    };
  },
  created() {
    
  },
  methods: {
    
  },
};
</script>
